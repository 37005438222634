<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h2>News Feed</h2>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="javascript:void(0)"> {{ blogTitle }}</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-8">
              <div class="saasio-blog-details-content">
                <div class="blog-details-img">
                  <img src="assets/images/blogs/crm7.PNG" alt="Un logiciel CRM et statistiques ?" />
                </div>
                <div class="blog-details-text dia-headline">
                  <h1>{{ blogTitle }}</h1>
                  <article>
                    Un logiciel CRM (Customer Relationship Management) peut faciliter votre travail de plusieurs manières en vous aidant à mieux gérer les interactions avec vos clients. Voici quelques exemples:
                  </article>
                  <article>
                    1. Organisation des données clients: Les logiciels CRM vous permettent de stocker toutes les informations relatives à vos clients en un seul endroit, ce qui facilite la consultation et la mise à jour des données. Vous pouvez ainsi avoir une vue d'ensemble de toutes les interactions que vous avez eues avec un client, ce qui peut vous aider à mieux comprendre ses besoins et à personnaliser votre approche.
                  </article>
                  <article>
                    2. Automatisation des tâches: Les logiciels CRM peuvent automatiser certaines tâches chronophages, telles que la gestion des contacts, l'envoi d'e-mails et le suivi des opportunités de vente. Cela vous permet de gagner du temps et de vous concentrer sur des tâches plus importantes, comme la conclusion de ventes.
                  </article>
                  <article>
                    3. Collaboration améliorée: Les logiciels CRM peuvent faciliter la collaboration entre les membres de votre équipe en permettant le partage des données clients et des tâches. Vous pouvez ainsi travailler plus efficacement et coordonner les actions de votre équipe.
                  </article>
                  <article>
                    4. Analyse des données: Les logiciels CRM peuvent vous aider à analyser les données clients pour mieux comprendre leur comportement et leurs besoins. Vous pouvez ainsi prendre des décisions éclairées et adapter votre approche en fonction des données.
                  </article>
                  <article>
                    En résumé, un logiciel CRM peut faciliter votre travail en vous aidant à mieux organiser vos données clients, à automatiser certaines tâches, à améliorer la collaboration et à analyser les données pour prendre des décisions éclairées.
                  </article>
                </div>
                
                <ShareContent />

              </div>
            </div>
            
            <RightSideBar />

          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
import ShareContent from '../components/ShareContent.vue';
import RightSideBar from '../components/RightSideBar.vue';
export default {
    name : 'BlogDetail7',
    components  : {
      ShareContent,
      RightSideBar
    },
    data() {
      return {
        //blogDetail : require('../assets/blogs/crm9.PNG'),
        blogTitle : "Comment Un logiciel CRM peut facilité mon travail ?"
      }
    }
};
</script>

<style>
</style>